import axios from 'axios'
import { apiEndPoints, serverAPIUrl, serverTopUpCode } from './api-config'
// topUpServerAPIUrl
import { clearUserData, getToken, saveTokens } from './storage-helper'

const getUserMaintenanceEndPoint = type => {
  switch (type) {
    case 'USERNAME':
      return apiEndPoints.changeUsername
    case 'PASSWORD':
      return apiEndPoints.changePassword
    case 'PINCODE':
      return apiEndPoints.changePinCode
    case 'EMAIL':
      return apiEndPoints.changeEmail
    case 'GAMETIME_GAMEPOINTS':
      return apiEndPoints.convertGameTimeToGamePoints
    case 'FORGOT_PIN_CODE':
      return apiEndPoints.forgotPinCode
    case 'FORGOT_CHARPIN':
      return apiEndPoints.forgotCharPin
    case 'FORGOT_PASSWORD':
      return apiEndPoints.forgotPassword
    case 'RESET_PASSWORD':
      return apiEndPoints.resetPassword
    case 'REGISTER':
      return apiEndPoints.registerUser
    case 'PILOT_MODE':
      return apiEndPoints.updatePilotMode
    case 'CHARPIN':
        return apiEndPoints.changeCharPin
    default:
      return null
  }
}

export const createWhitelist = async (param, setAuthentication) => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getWhitelists

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(apiEndPoint, param, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }
}

export const getWhitelists = async setAuthentication => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getWhitelists

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const uploadImageToImgBB = async param => {
  let endPoint = 'https://api.imgbb.com/1/upload'
  let apiKey = 'b8b818d73b982df9234d6b95bafb82ab'

  let formData = new FormData()
  formData.append('key', apiKey)
  formData.append('image', param.file)

  try {
    axios.defaults.withCredentials = false
    var result = await axios.post(endPoint, formData, {
      headers: { Accept: 'application/json' }
    })

    if (result.status === 200) {
      return result.data.data
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

export const replyTicket = async (param, setAuthentication) => {
  let endPoint = serverAPIUrl + apiEndPoints.requestTicket + '/reply'

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(endPoint, param, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      if (setAuthentication === undefined || setAuthentication === null)
        window.location.reload(false)
      else setAuthentication(false)
      return null
    }
  }
}

export const getTicketById = async (param /*, setAuthentication*/) => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.requestTicket + '/' + param.id

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      // if(setAuthentication=== undefined || setAuthentication === null)
      //     window.location.reload(false)
      // else
      //     setAuthentication(false)

      return null
    }
  }

  return null
}

export const createTicket = async (param, setAuthentication) => {
  let formData = new FormData()
  //formData.append("file", param.file);
  formData.append('referenceNumber', param.referenceNumber)
  formData.append('message', param.message)
  formData.append('amount', Number(param.amount))
  formData.append('fileName', param.fileName)

  let endPoint = serverAPIUrl + apiEndPoints.requestTicket

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(endPoint, formData, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }
}

export const getTickets = async (param, setAuthentication) => {
  if (param === undefined) param = 1

  let apiEndPoint = serverAPIUrl + apiEndPoints.getTickets + '/' + param

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const shopBuyItem = async (param, setAuthentication) => {
  let endPoint = serverAPIUrl + apiEndPoints.shopBuyItem + param.id

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(endPoint, param, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }
}

export const getShopItems = async (param, pageNumber, setAuthentication) => {
  let apiEndPoint =
    serverAPIUrl +
    apiEndPoints.getShopItems +
    'type/' +
    param.typeId +
    '/category/' +
    param.categoryId +
    '/' +
    pageNumber

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const getShopRecords = async (param, setAuthentication) => {
  if (param === undefined) param = 1

  let apiEndPoint = serverAPIUrl + apiEndPoints.getShopRecords + param

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      // var refreshTokenRequest = await refreshJWTToken();
      // if(refreshTokenRequest) {
      //     var requestAgain = await getShopRecords(setAuthentication)
      //     return requestAgain;
      // }else{
      //     setAuthentication(false)
      //     resetUserLocalData()
      //     return null;
      // }
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const getTopupRecords = async (param, setAuthentication) => {
  if (param === undefined) param = 1

  let apiEndPoint = serverAPIUrl + apiEndPoints.getTopupRecords + param

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const requestTopup = async param => {
  let constructEndPoint = `${param.id}`
  param.serverCode = serverTopUpCode
  let endPoint = serverAPIUrl + apiEndPoints.requestTopup + constructEndPoint

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(endPoint, param, { withCredentials: true })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: [
          'There is a problem while processing your request. Please try again later.'
        ],
        status: 404
      }
    }
  }
}

export const topUpCheckOut = async param => {
  //let apiEndPoint = topUpServerAPIUrl + apiEndPoints.topUpCheckOut + param.id + '/' + serverTopUpCode;
  let apiEndPoint = serverAPIUrl + apiEndPoints.topUpCheckOut + param.id

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === undefined || data.response === null) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        status: 404
      }
    }
  }

  return null
}

export const userMaintenance = async (type, param, setAuthentication) => {
  const endPoint = getUserMaintenanceEndPoint(type)

  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(serverAPIUrl + endPoint, param, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      // update token.
      if (type === 'EMAIL' || type === 'USERNAME') {
        saveTokens(result.data.data)
      }
      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error,
        status: 400
      }
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        errors: ['There is a problem while processing your request.'],
        status: 404
      }
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }
}

export const requestLogout = async setAuthentication => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.requestLogout

  try {
    var result = await axios.post(
      apiEndPoint,
      {},
      {
        withCredentials: true,
        headers: { Authorization: `Bearer ${getToken()}` }
      }
    )

    if (result.status === 200) {
      clearUserData()
      setAuthentication(false)
      return true
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return {
        success: true,
        status: 401
      }
    }
  }

  return false
}

export const getUserGameTime = async setAuthentication => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getUserGameTime

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const getUserAccountInfo = async setAuthentication => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getUserAccountInfo

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const getUserBasicInfo = async setAuthentication => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getUserBasicInfo

  try {
    var result = await axios.get(apiEndPoint, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${getToken()}` }
    })

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === null || data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 401) {
      clearUserData()
      setAuthentication(false)
      return null
    }
  }

  return null
}

export const getNewsById = async param => {
  let apiEndPoint = serverAPIUrl + apiEndPoints.getNewsById + param.id

  try {
    var result = await axios.get(apiEndPoint)

    if (result.status === 200) {
      return {
        success: true,
        data: result.data.data,
        status: 200
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === undefined || data.response === null) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 404) {
      return {
        success: false,
        status: 404
      }
    }
  }

  return null
}

export const getRankings = async param => {
  let constructEndPoint = `/type/${param.type}/class/${param.class}/school/${param.school}`

  let apiEndPoint = serverAPIUrl + apiEndPoints.getRankings + constructEndPoint

  var result = await fetch(apiEndPoint)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

export const getPremiumPoints = async () => {
  var result = await fetch(serverAPIUrl + apiEndPoints.getPremiumPoints)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

export const getDownloads = async () => {
  var result = await fetch(serverAPIUrl + apiEndPoints.getDownloads)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

export const getNews = async () => {
  var result = await fetch(serverAPIUrl + apiEndPoints.getNews)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

export const getIndividualRankings = async () => {
  var result = await fetch(serverAPIUrl + apiEndPoints.getindividualRankings)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

export const getElectronicRoomsInfo = async () => {
  var result = await fetch(serverAPIUrl + apiEndPoints.getElectronicRoomsInfo)

  if (result.status === 200) {
    var data = await result.json()
    return data
  }

  return null
}

// const refreshJWTToken = async ()  => {
//     let apiEndPoint = serverAPIUrl + apiEndPoints.requestRefreshToken;

//     try{
//         axios.defaults.withCredentials = true
//         var result = await axios.post(apiEndPoint, { withCredentials: true})

//         if(result.status === 200){
//             return true;
//         }
//     }catch(error) {
//         return false;
//     }

//     return false;
// }
