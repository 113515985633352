//export const serverAPIUrl = "https://localhost:44342/"
export const serverAPIUrl = 'https://ranonlineph-api.com/'
export const topUpServerAPIUrl = 'https://ranonlineph-api.com/'
export const serverTopUpCode = 'ranonlineph_xendit'
export const serverItemShopImageStorage = serverAPIUrl + 'assets/images/items/'
export const serverTicketImageStorage =
  serverAPIUrl + 'assets/images/donations/'

export const apiEndPoints = {
  authenticateUser: 'api/auth/login',
  requestLogout: 'api/user/logout',
  requestRefreshToken: 'api/user/refresh-token',
  getindividualRankings: 'api/component/individual-top-ranks',
  getElectronicRoomsInfo: 'api/component/erooms-info',
  getNews: 'api/component/news',
  getDownloads: 'api/component/downloads',
  getPremiumPoints: 'api/component/topup/premium-points',
  getRankings: 'api/component/rankings',
  getNewsById: 'api/component/news/',
  getUserBasicInfo: 'api/component/user-basic-info',
  getUserAccountInfo: 'api/component/user-info',
  changePassword: 'api/user/change-password',
  changePinCode: 'api/user/change-pin',
  changeCharPin: 'api/user/change-char-pin',
  changeEmail: 'api/user/change-email',
  resetPassword: 'api/user/reset-password',
  forgotPassword: 'api/user/forgot-password',
  forgotPinCode: 'api/user/forgot-pin',
  forgotCharPin: 'api/user/forgot-char-pin',
  registerUser: 'api/user/register',
  getUserGameTime: 'api/convert/gametime-to-gamepoints',
  convertGameTimeToGamePoints: 'api/convert/gametime-to-gamepoints',
  convertPremiumPointsToGamePoints: 'api/convert/premiumpoints-to-gamepoints/',
  topUpCheckOut: 'api/component/topup/check-out/',
  requestTopup: 'api/component/topup/check-out/',
  getTopupRecords: 'api/component/topup/records/page/',
  getShopRecords: 'api/component/shop/records/page/',
  updatePilotMode: 'api/user/pilot-mode',
  getShopItems: 'api/component/shop/items/',
  shopBuyItem: 'api/component/shop/items/buy/',
  requestTicket: 'api/component/helpdesk/ticket',
  getTickets: 'api/component/helpdesk/tickets',
  getWhitelists: 'api/component/whitelists',
  changeUsername: 'api/user/change-username'
}
