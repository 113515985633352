import { useState, useEffect } from 'react'
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Navigate
} from 'react-router-dom'

import { Box, Heading, Text } from '@chakra-ui/react'

//import * as utils from '../lib/function-helper'

import Page from '../components/page'
import Background from '../components/background'
import AccountList from '../components/account/list'
import AccountTabs from '../components/account/tabs'
import { useRanContext } from '../components/ran-state-provider'

import userIcon from '../assets/icons/user.png'
import passwordIcon from '../assets/icons/password.png'
import pinIcon from '../assets/icons/pin.png'
import pinForgotIcon from '../assets/icons/pin-forgot.png'
import emailIcon from '../assets/icons/email.png'
import helpDeskIcon from '../assets/icons/help-desk.png'
import coinSwapIcon from '../assets/icons/coin-swap.png'
import coPilotIcon from '../assets/icons/co-pilot.png'
import whitelistIcon from '../assets/icons/whitelist.png'
import recordsIcon from '../assets/icons/records.png'

const options = [
  { tab: 'view', icon: userIcon, text: 'VIEW ACCOUNT' },
  { tab: 'change-username', icon: passwordIcon, text: 'CHANGE USERNAME' },
  { tab: 'change-password', icon: passwordIcon, text: 'CHANGE PASSWORD' },
  { tab: 'change-pin', icon: pinIcon, text: 'CHANGE PIN CODE' },
  { tab: 'change-char-pin', icon: pinIcon, text: 'CHANGE CHAR PIN' },
  { tab: 'forgot-pin', icon: pinForgotIcon, text: 'FORGOT PIN CODE' },
  { tab: 'forgot-char-pin', icon: pinForgotIcon, text: 'FORGOT CHAR PIN' },
  { tab: 'change-email', icon: emailIcon, text: 'CHANGE EMAIL' },
  { tab: 'help-desk', icon: helpDeskIcon, text: 'HELP DESK' },
  {
    tab: 'gametime-gamepoints',
    icon: coinSwapIcon,
    text: 'GAMETIME TO GAMEPOINTS'
  },
  { tab: 'pilot-mode', icon: coPilotIcon, text: 'PILOT MODE' },
  { tab: 'ip-whitelists', icon: whitelistIcon, text: 'IP WHITELISTS' },
  { tab: 'shop-records', icon: recordsIcon, text: 'SHOP RECORDS' }
]

const Account = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isAuthenticated, userBasicInfo, setToast } = useRanContext()

  const [tab, setTab] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setTab(searchParams.get('tab'))
  }, [location])

  const AccountHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      {userBasicInfo?.username}
      <Box fontSize={14} fontWeight={400}>
        <Text>E-Points: {userBasicInfo?.premiumPoints}</Text>
        <Text>Game Points: {userBasicInfo?.gamePoints}</Text>
      </Box>
    </Heading>
  )

  return !isAuthenticated ? (
    <Navigate to="/login" replace={true} />
  ) : (
    <>
      <Page
        title="Account"
        header={<AccountHeader />}
        narrowProfile
        highProfile
      >
        <AccountList
          options={options}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </Page>

      <Background highProfile />

      <AccountTabs
        tab={tab}
        onClose={() => {
          navigate('/account')
        }}
        setToast={setToast}
      />
    </>
  )
}

export default Account
